import React, { useRef, RefObject, useContext, useMemo } from 'react';
import { Logger } from '@feature-hub/core';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { GfaAuthServiceProviderV1 } from '@volkswagen-onehub/gfa-auth-service';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ITrackingServiceV1 } from '@oneaudi/tracking-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';

import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { Dependencies } from '../FeatureAppTypes';

interface State {
  readonly asyncSsrManager?: AsyncSsrManagerV1;
  readonly authServiceProvider: GfaAuthServiceProviderV1;
  readonly logger?: Logger;
  readonly featureAppGroup: string;
  readonly i18nService: I18NServiceV1;
  readonly inViewRef: RefObject<HTMLDivElement>;
  readonly localeService: GfaLocaleServiceV1;
  readonly serializedStateManager?: SerializedStateManagerV1;
  readonly trackingService?: ITrackingServiceV1;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
}

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly children: JSX.Element;
  readonly featureAppGroup: string;
}

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({
  featureServices,
  children,
  featureAppGroup,
}: ContextValue): JSX.Element => {
  const {
    'gfa:auth-service': authServiceProvider,
    'dbad:audi-i18n-service': i18nService,
    's2:logger': logger,
    'gfa:locale-service': localeService,
    'audi-tracking-service': trackingService,
    's2:async-ssr-manager': asyncSsrManager,
    's2:serialized-state-manager': serializedStateManager,
    'dbad:audi-vue-formatter-service': vueFormatterService,
  } = featureServices;
  const inViewRef = useRef<HTMLDivElement>(null);

  const state: State = useMemo(
    () => ({
      asyncSsrManager,
      authServiceProvider,
      featureAppGroup,
      i18nService,
      inViewRef,
      localeService,
      logger,
      serializedStateManager,
      trackingService,
      vueFormatterService,
    }),
    [
      asyncSsrManager,
      authServiceProvider,
      featureAppGroup,
      i18nService,
      inViewRef,
      localeService,
      logger,
      serializedStateManager,
      trackingService,
      vueFormatterService,
    ],
  );

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};

export const useLogger = (): Logger | undefined => useContext(Context).logger;
