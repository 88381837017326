import { Asset } from '@oneaudi/falcon-tools';
import {
  Content,
  ContentVariant,
  HeadlessContent,
  HeadlessContentVariant,
} from '../EditorContentTypes';

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      defaultContent: {
        individualId: content.fields.defaultContent_individualId,
        text: content.fields.defaultContent_text,
        image: {
          xs: {
            assetValue: (content.fields.defaultContent_image_xs as Asset).path,
          },
          m: {
            assetValue: (content.fields.defaultContent_image_m as Asset).path,
          },
          xxl: {
            assetValue: (content.fields.defaultContent_image_xxl as Asset).path,
          },
          altText: content.fields.defaultContent_image_altText,
        },
        legalData: {
          additionalText: content.fields.defaultContent_legalData_additionalText,
          wltpKeys: content.fields.defaultContent_legalData_wltpKeys,
        },
      },
      variants: mapContentVariant(content.fields.variants),
      buttons: {
        registerButtonUrl: content.fields.buttons_registerButtonUrl,
        learnMoreButtonUrl: content.fields.buttons_learnMoreButtonUrl,
      },
    };
    return normalisedContent;
  }
  return content as Content;
};
function mapContentVariant(variants: HeadlessContentVariant[]): ContentVariant[] {
  const contentVariants: ContentVariant[] = [];

  variants.forEach((variant) => {
    contentVariants.push({
      individualId: variant.fields.individualId,
      text: variant.fields.text,
      image: {
        xs: {
          assetValue: (variant.fields.image_xs as Asset).path,
        },
        m: {
          assetValue: (variant.fields.image_m as Asset).path,
        },
        xxl: {
          assetValue: (variant.fields.image_xxl as Asset).path,
        },
        altText: variant.fields.image_altText,
      },
      legalData: {
        additionalText: variant.fields.legalData_additionalText,
        wltpKeys: variant.fields.legalData_wltpKeys,
      },
    });
  });
  return contentVariants;
}
